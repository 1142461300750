import { Fill, Stroke, Style } from 'ol/style'
import { heatMap } from '../../../../../utils/colors/chartPalette'

const getDataIntervalFromRange = (range, numBins) => {
    const [dataMin, dataMax] = range
    const diff = dataMax - dataMin
    const interval = diff / numBins
    return interval
}

const getHeatMapColorKeyFromRange = (value, range) => {
    // if value is 0,
    // return undefined to give the transparent
    // color in themeMapStyleFunction
    if (value === 0) {
        return undefined
    }
    const [dataMin] = range
    const interval = getDataIntervalFromRange(range, heatMap.length)
    const colorKey = heatMap
                            .map((_, idx) => dataMin + (idx + 1) * interval)
                            .findIndex(x => x + 1> value)
    return colorKey
}

const getLegendDataFromRange = range => {
    const [dataMin] = range
    const interval = getDataIntervalFromRange(range, heatMap.length)
    return heatMap.map((_, idx) => {
        return {
            color: heatMap[idx],
            dataMin: Math.ceil(dataMin + (idx * interval)),
            dataMax: Math.ceil(dataMin + ((idx + 1) * interval))
        }
    })
}

const heatMapStyles = heatMap.map((_, idx) => idx).reduce((acc, current) => {
    const color = heatMap[current]
    return {
        ...acc,
        [current]: new Style({
            fill: new Fill({
                color,
            }),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        })
    }
}, {})

const defaultCountyStyle = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0)'
    }),
    stroke: new Stroke({
        color: 'black',
        width: 1
    })
})

const themeMapStyleFunction = (feature) => {
    const colorKey = feature.get('ColorKey')
    if (typeof colorKey !== 'undefined') {
        return heatMapStyles[feature.get('ColorKey')]
    } else {
        return defaultCountyStyle
    }
}

export { themeMapStyleFunction, getHeatMapColorKeyFromRange, getLegendDataFromRange }