export default [
    'ID',
    'Name',
    'API 14',
    'OperatorKey',
    'Operator Number',
    'API No',
    'Well Name',
    'Incident',
    'Postal Code',
    'Lease',
    "Well Type",
    "Producing Geological Formation",
    "Surface Managing Entity",
    "Subsurface Managing Entity",
    "Tribe",
    "Pre-Plugging Methane Emissions (grams/hr)",
    "Post-Plugging Methane Emissions (grams/hr)",
    "Methane Measurement Method",
    "Habitat Restored (acres)",
    "Restoration Endpoint",
    "Surface Water Contamination Indicators",
    "Surface Water Remediation Method",
    "Groundwater Contamination Indicators",
    "Groundwater Remediation Method",
    "Community Impact Indicators",
    "Witness Name & Certification",
    "Result",
    "Place Value"
]